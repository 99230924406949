<template>
  <v-container fluid>
    <k-ask-change-btn> Novo serviço </k-ask-change-btn>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :disable-sort="$vuetify.breakpoint.smAndDown"
          :headers="headers"
          :items-per-page.sync="itemsPerPage"
          :items="filteredItems"
          hide-default-footer
          :loading="loading"
          :hide-default-header="$vuetify.breakpoint.smAndDown"
        >
          <template v-slot:item.name="{ item }">
            <div class="text-left">{{ item.name }}</div>
          </template>
          <template v-slot:item.statusPercentage="{ item }">
            {{ item.statusPercentage | toFixed(2) }}%
          </template>
          <template v-slot:item.requestedAt="{ item }">
            {{ item.requestedAt | moment('DD/MM/YYYY') }}
          </template>
          <template v-slot:item.finishedAt="{ item }">
            {{ item.finishedAt | moment('DD/MM/YYYY') }}
          </template>
          <template v-slot:item.value="{ item }">
            {{ item.value | currencyFromCents }}
          </template>
          <template v-slot:item.actions="{ item }">
            <span>
              <v-btn
                width="100px"
                color="green"
                small
                rounded
                title="Aprovar Serviço"
                dark
                @click="goToViewOrderService(item)"
                v-if="item.additionalServiceStepId === 1"
              >
                Aprovar
              </v-btn>
              <v-btn
                width="100px"
                color="primary"
                small
                rounded
                title="Visualizar detalhes"
                @click="goToViewOrderService(item)"
                v-else
              >
                Visualizar
              </v-btn>
            </span>
          </template>
          <template slot="no-data">
            <p class="text-center blue--text py-6 text-h5">
              <v-icon color="blue" medium>info</v-icon> Não há serviços
              adicionais.
            </p>
          </template>
        </v-data-table>
        <v-col cols="12">
          <div class="text-center pt-2">
            <v-btn
              color="info"
              v-show="itemsPerPage < filteredItems.length"
              @click="itemsPerPage += 5"
              >visualizar mais</v-btn
            >
          </div>
        </v-col>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'

const kAskChangeBtn = () => import('@src/components/widget/AskChangeBtn.vue')

export default {
  name: 'AdditionalList',
  components: {
    kAskChangeBtn,
  },
  data() {
    return {
      snackbar: false,
      loading: false,
      search: null,
      itemsPerPage: 5,
      descending: false,
      menu: false,
      sortBy: ['requestedAt'],
      sortDesc: true,

      headerMdAndUp: [
        {
          text: 'Serviço Adicional',
          align: 'left',
          value: 'name',
          width: '30%',
        },
        {
          text: 'Situação',
          value: 'situation',
          align: 'left',
          width: '30%',
          class: [],
        },
        {
          text: 'Status',
          value: 'statusPercentage',
          align: 'center',
        },
        {
          text: 'Data de Aprovação',
          value: 'requestedAt',
          align: 'center',
        },
        {
          text: 'Data da Finalização',
          value: 'finishedAt',
          align: 'center',
        },
        {
          text: 'Valor',
          value: 'value',
          align: 'left',
          sortable: false,
        },
        {
          text: 'Ações',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],
      headerMdAndDown: [
        {
          text: 'Serviço Adicional',
          value: 'name',
          align: 'left',
          class: 'text-left',
        },
        {
          value: 'actions',
          sortable: false,
          align: 'center',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      all: (state) => state.additionalService.services,
      tableitemsPerPage: (state) => state.layout.tableitemsPerPage,
      itemsPerPageText: (state) => state.layout.itemsPerPageText,
      filteredItems: function () {
        return this.all.filter((i) => {
          return i
        })
      },
    }),
    headers() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return this.headerMdAndUp
      } else {
        return this.headerMdAndDown
      }
    },
  },

  methods: {
    ...mapActions({
      getAdditionalService: 'additionalService/getListOrderService',
      dialogsChangeAproveService: 'dialogs/changeAproveService',
      dialogsChangeDetailService: 'dialogs/changeDetailService',
      dialogsChangeAdditionalService: 'dialogs/changeAdditionalService',
    }),
    goToViewOrderService(item) {
      this.$router.push({
        name: 'DetailService',
        params: { idOrderService: item.id },
      })
    },
  },
  mounted() {
    this.loading = true
    this.getAdditionalService().then(() => {
      this.loading = false
    })
  },
}
</script>
<style scoped></style>
